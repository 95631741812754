import React from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import './HeaderComponent.scss';
import { myStyles } from './styles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const HeaderComponent = (props) => {
  const { heroImage, circularSectionTitle, circularSectionDescription, classes } = props;
  const classes1 = myStyles(heroImage);
  return (
    <Grid className="header-div">
      <Grid container className='header-container'>
        <Grid item xs={12} sm={12} md={12} lg={10} xl={10} className={clsx('header-hero-bg', classes1['header-hero-bg'])}>
           <div className={clsx('blue-round-div', classes['blue-round-div'])}>
            <h1 className="title" locId="circularSectionTitle">
              {circularSectionTitle}
            </h1>
            <p className='blue-sec-desp'>
              {circularSectionDescription?.raw && documentToReactComponents(JSON.parse(circularSectionDescription?.raw))}
            </p>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default HeaderComponent;
