import React, { useRef, useEffect } from "react";
import './AwardsSlider.scss';
import { Grid, Card } from '@material-ui/core';
import Carousel from 'react-elastic-carousel';
import { myStyles } from './styles';
import clsx from 'clsx';

export default function AwardsSlider({ awardsSection,backgroundImg, autoRotateEnabled }) {
  const numberOfAwards = process.env.GATSBY_NUMBER_OF_PROUD_ASSOCIATION_AND_AWARDS;
  const AwardList = [...awardsSection].splice(0, numberOfAwards);

  const classes = myStyles(backgroundImg);
  const breakPoints = [
    { width: 1, itemsToShow: 2, itemsToScroll: 1, itemPadding: [0, 10], showArrows: AwardList.length > 2, pagination: AwardList.length > 2 },
    { width: 200, itemsToShow: 3, itemsToScroll: 1, itemPadding: [0, 10], showArrows: AwardList.length > 3, pagination: AwardList.length > 3 },
    { width: 904, itemsToShow: 5, itemsToScroll: 1, itemPadding: [0, 10], showArrows: AwardList.length > 5, pagination: AwardList.length > 5 },
    { width: 1239, itemsToShow: 5, itemsToScroll: 1, showArrows: AwardList.length > 5, pagination: AwardList.length > 5 }
  ];
  const carouselRef = useRef(null);
  let resetTimeout;
  const carouselProps = {
    breakPoints:breakPoints,
    itemPadding:[0, 20],
    infinite: true,
  }
  if(autoRotateEnabled){
    carouselProps.ref=carouselRef
    carouselProps.infiniteLoop = true
    carouselProps.enableAutoPlay= true
    carouselProps.autoPlaySpeed=5000
    carouselProps.isRTL=false
    carouselProps.onNextEnd=({ index }) => {
      clearTimeout(resetTimeout);
      resetTimeout = setTimeout(() => {
        carouselRef?.current?.goTo(0);
      }, 5500);
    }
  }
  const buttonEl = useRef(null);
  const registerAriaLabel = () => {
    buttonEl?.current?.querySelectorAll(".rec-dot").forEach((role) => {
      role.setAttribute('aria-label', 'pagination');
    });
  }
  useEffect(() => {
    registerAriaLabel();
    if (window) {
      window.addEventListener("resize", () => { registerAriaLabel() })
    }
  }, []);
 
  return (
    <Grid item xs={12} ref={buttonEl}>
        <Grid item xs={12} className={clsx("image_slider",classes['image_slider'])}>
        {
          <Carousel {...carouselProps}>
            
            {
              AwardList.map((item, i) => <Item key={i} item={item} />)
            }
          </Carousel>
        }
      </Grid>
    </Grid>
  )
}


function Item({ item }) {
  return (
    <>
      <Card className="root" >
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="our-img-slider-bg" direction="column" >
          <div className="Ourslide_img">
            <img src={item?.file?.url+'?fm=webp&q=100'} alt={item?.description} locId={item?.description} />
          </div>
        </Grid>
      </Card>
    </>
  )
}
