import React from 'react';
import clsx from 'clsx';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import ImageVideoComponent from '../ImageVideoComponent/Index';
const HeaderIntroComponent = (props) => {
  const {
    storyhistorySection,
    classes,
    aboutUsStorySectionTopic,
    aboutUsStorySectionDescription,
    button,
    basePath
  } = props;
  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className='header-intro-div'>
      <Grid item xs={6} sm={5} md={5} lg={7} xl={6}>
        <h2 className="title" locId="storyhistorySectionHeader">
          {storyhistorySection?.header}
        </h2>
      </Grid>
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
          <h3 className="sub-title" locId="storyhistorySectionSubheader">
            {storyhistorySection?.subHeader}
          </h3>
          <p locId="storyhistorySectionShortDescription" className='description'>
            {documentToReactComponents(
              JSON.parse(storyhistorySection?.shortDescription?.raw)
            )}
          </p>
          {(button?.label || button) &&
            (button?.link?.url ? (
              <Button
                variant="outlined"
                className="our-solutions-btn"
                locId="buttonLabel"
                tabIndex="0"
                href={button?.link?.url ? button?.link?.url : '#'}
              >
                {button?.label}
              </Button>
            ) : (
              <Button
                variant="outlined"
                className="our-solutions-btn"
                locId="buttonLabel"
                tabIndex="0"
                href={button?.link?.slug ? `/${basePath}/${button?.link?.slug}` : '#'}
              >
                {button?.label}
              </Button>
            ))}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
          <div className='image-box'>
            <div className={clsx('video-wrap', classes['image-box'])}>
              <ImageVideoComponent storyhistorySection={storyhistorySection} thumbnailAltText={aboutUsStorySectionTopic}/></div>
            <Paper className="video-content" elevation={0}>
              {aboutUsStorySectionTopic && (
                <Typography className="video-title" locId="aboutUsStorySectionTopic">
                  {aboutUsStorySectionTopic}
                </Typography>
              )}
              {aboutUsStorySectionDescription && (
                <Typography className="video-subtitle" locId="aboutUsStorySectionDescription">
                  {aboutUsStorySectionDescription}
                </Typography>
              )}
            </Paper>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default HeaderIntroComponent;
